export const LEAF_TYPE_OPTIONS = [
  { value: 'SOURCE', text: 'Ingest / Source (Encoder/etc)' },
  { value: 'DEST', text: 'Receiver / Destination (Decoder/etc)' },
  { value: 'STANDARDS_CONVERTER', text: 'Standards Converter' },
];

export const LEAF_EQUIPMENT_TYPE_OPTIONS = [
  { value: 'ADTEC_EN100', label: 'ADTEC-EN100' },
  { value: 'ADTEC_EN200', label: 'ADTEC-EN200' },
  { value: 'ADTEC_EN40', label: 'ADTEC-EN40' },
  { value: 'ADTEC_EN91', label: 'ADTEC-EN91' },
  { value: 'ADTEC_RD60', label: 'ADTEC-RD60' },
  { value: 'ADTEC_SIGNEDJE', label: 'ADTEC-SIGNEDJE' },
  { value: 'ADTEC_SOLOIST_PRO_HD', label: 'ADTEC-SoloistPro-HD' },
  { value: 'ATEME_KYRION_CM5000', label: 'ATEME-KYRION-CM5000' },
  { value: 'ATEME_KYRION_DM5000', label: 'ATEME-KYRION-DR5000' },
  { value: 'AWS_MEDIA_CONNECT', label: 'AWS-MEDIA-CONNECT' },
  { value: 'CONNECTOR', label: 'CONNECTOR' },
  { value: 'DEKTEC_DTE_3100', label: 'DEKTEC-DTE-3100' },
  { value: 'DEKTEC_DTE_3120', label: 'DEKTEC-DTE-3120' },
  { value: 'ELEMENTAL_LIVE', label: 'ELEMENTAL-LIVE' },
  { value: 'ERICSSON_8200', label: 'ERICSSON-8200' },
  { value: 'FEC_RTP', label: 'FEC/RTP' },
  { value: 'FUJITSU_IP_900E', label: 'FUJITSU-IP-900E' },
  { value: 'FUJITSU_IP_9500', label: 'FUJITSU-IP-9500' },
  { value: 'FUJITSU_IP_9500E', label: 'FUJITSU-IP-9500E' },
  { value: 'FUJITSU_IP_9610', label: 'FUJITSU-IP-9610' },
  { value: 'HAI_MAKO', label: 'HAI-MAKO' },
  { value: 'HAI_MIKITO', label: 'HAI-MIKITO' },
  { value: 'HARMONIC_PVR700', label: 'HARMONIC-PVR7000' },
  { value: 'HARMONIC_PVR8130', label: 'HARMONIC-PVR8130' },
  { value: 'HARMONIC_XOS', label: 'HARMONIC-XOS' },
  { value: 'HARRIS_IPA6800', label: 'Harris-IPA6800' },
  { value: 'HARRIS_MDF6800', label: 'Harris-MDF6800' },
  { value: 'IP', label: 'IP' },
  { value: 'LTN_AUDIO_DEEMBEDDER', label: 'LTN-AUDIO-DEEMBEDDER' },
  { value: 'LTN_DEC', label: 'LTN-DECODER' },
  { value: 'OBE_DECKLINK_1', label: 'LTN-ENCODER' },
  { value: 'LTN_THUMBNAILER', label: 'LTN-THUMBNAILER' },
  { value: 'NTT_MVD5000', label: 'NTT-MVD5000' },
  { value: 'NTT_MVE5000', label: 'NTT-MVE5000' },
  { value: 'OTHER', label: 'OTHER' },
  { value: 'PID_DROPPER', label: 'PID Dropper' },
  { value: 'SENCORE_3045', label: 'SENCORE-3045' },
  { value: 'SENCORE_3187', label: 'SENCORE-3187' },
  { value: 'SENCORE_4400', label: 'SENCORE-4400' },
  { value: 'SENCORE_5800', label: 'SENCORE-5800' },
  { value: 'SENCORE_AG_4400', label: 'SENCORE-AG-4400' },
  { value: 'SENCORE_AG_5800', label: 'SENCORE-AG-5800' },
  { value: 'SENCORE_DMG_1200', label: 'SENCORE-IP/ASI-DMG-1200' },
  { value: 'SRT_STATIC', label: 'SRT-STATIC' },
  { value: 'T21_9261_D', label: 'T21-9261-D' },
  { value: 'TAG_MV', label: 'TAG-MV' },
  { value: 'TERANEX_AV', label: 'TERANEX-AV' },
  { value: 'TERRADEK', label: 'TERRADEK' },
  { value: 'TRANSP_V', label: 'TRANSP-V' },
  { value: 'UPCOM_UC_IRD', label: 'UPCOM-UC-IRD' },
  { value: 'UDP_CONNECTOR', label: 'UDP-CONNECTOR' },
  { value: 'VBRICK_MP2', label: 'VBRICK-MP2' },
  { value: 'VL_READYCAM_E', label: 'VL-READYCAM-E' },
];

export const LEAF_LATENCY_OPTIONS = [
  { value: 'NORMAL', text: 'normal (ipb frames)' },
  { value: 'VERY_LOW_WITH_IFRAMES', text: 'very low (ip frames)' },
  { value: 'VERY_LOW', text: 'very_low (intra-refresh p frames)' },
];

export const LEAF_RESOLUTION_OPTIONS = [
  { value: 'SD_480I', text: 'SD-480i' },
  { value: 'HD_720P', text: 'HD-720p' },
  { value: 'HD_1080I', text: 'HD-1080i' },
  { value: 'HD_1080P', text: 'HD-1080p' },
  { value: 'UHD_2160P', text: 'UHD-2160p (4K)' },
];

export const LEAF_ROLE_OPTIONS = [
  { text: '', value: '' },
  { value: 'PRIMARY', text: 'Primary' },
  { value: 'BACKUP', text: 'Backup (only the primary will be bookable)' },
];

export const SDI_CARD_INDEX_OPTIONS = [
  { text: '', value: '' },
  { text: 'index0 :: DUO IN1/OUT1 :: DUO2/8KPRO Port-A :: QUAD2 Port-A :: NDI Port 0', value: '0' },
  { text: 'index1 :: DUO IN2/OUT2 :: DUO2/8KPRO Port-C :: QUAD2 Port-C :: NDI Port 1', value: '1' },
  { text: 'index2 :: DUO2/8KPRO Port-B :: QUAD2 Port-E :: NDI Port 2', value: '2' },
  { text: 'index3 :: DUO2/8KPRO Port-D :: QUAD2 Port-G :: NDI Port 3', value: '3' },
  { text: 'index4 :: QUAD2 Port-B :: NDI Port 4', value: '4' },
  { text: 'index5 :: QUAD2 Port-D :: NDI Port 5', value: '5' },
  { text: 'index6 :: QUAD2 Port-F :: NDI Port 6', value: '6' },
  { text: 'index7 :: QUAD2 Port-H :: NDI Port 7', value: '7' },
];

export const SDI_CARD_INDEX_DICT = {
  0: 'index0 :: DUO IN1/OUT1 :: DUO2/8KPRO Port-A :: QUAD2 Port-A',
  1: 'index1 :: DUO IN2/OUT2 :: DUO2/8KPRO Port-C :: QUAD2 Port-C',
  2: 'index2 :: DUO2/8KPRO Port-B :: QUAD2 Port-E',
  3: 'index3 :: DUO2/8KPRO Port-D :: QUAD2 Port-G',
  4: 'index4 :: QUAD2 Port-B',
  5: 'index5 :: QUAD2 Port-D',
  6: 'index6 :: QUAD2 Port-F',
  7: 'index7 :: QUAD2 Port-H',
};

export const STREAM_MODE_OPTIONS = [
  { value: 'UDP', text: 'UDP' },
  { value: 'RTP', text: 'RTP' },
];

export const VIDEO_ENCODE_MODE_OPTIONS = [
  { value: 'UNKNOWN_VIDEO_ENCODE_MODE', text: 'EMPTY STRING' },
  { value: 'VARIABLE', text: 'Variable' },
  { value: 'CBR', text: 'CBR' },
];

export const VIDEO_CODEC_OPTIONS = [
  { value: 'UNKNOWN_VIDEO_CODEC', text: 'EMPTY STRING' },
  { value: 'AVC', text: 'AVC' },
  { value: 'HEVC', text: 'HEVC' },
  { value: 'AVC_VAAPI', text: 'AVC VAAPI' },
  { value: 'HEVC_VAAPI', text: 'HEVC VAAPI' },
  { value: 'HEVC_VEGA3311', text: 'HEVC VEGA3311' },
];

export const SIGNAL_SOURCE_OPTIONS = [
  { text: '', value: '' },
  { value: 'DECKLINK', text: 'DECKLINK' },
  { value: 'NDI', text: 'NDI' },
  { value: 'VEGA', text: 'VEGA' }
];

export const AUDIO_PAIR_OPTIONS = [
  { text: '', value: '' },
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
  { value: 5, text: '5' },
  { value: 6, text: '6' },
  { value: 7, text: '7' },
  { value: 8, text: '8' },
];

export const ENC_DEC_INDEPENDENT_PROFILE_FIELDS = [
  'description',
];

import { lazy } from 'react';
import { PERMISSION_WATCH } from 'Constants';

export default {
  // ui: 'menuLeft',
  ui: 'hidden',
  auth: { role: PERMISSION_WATCH },
  // path: 'watch',
  path: 'watch-v2',
  urlParams: '/:viewId?',
  title: 'Watch',
  component: lazy(() => import('./index')),
};

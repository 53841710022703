import React, {
  createContext, useContext, useEffect, useCallback, useState
} from 'react';
import jwtDecode from 'jwt-decode';
import { queryCache } from 'react-query';
import {
  authenticate, challengeAccepted, challengeAcceptedNoLogin, refreshToken as newToken
} from 'Services/auth.service';
import { APP_LOGIN_ROUTE, env, PERMISSION_WATCH } from 'Constants';
import { useNotifications } from 'Context/notifications';
import { readServerTime } from 'Components/current-time';

import debug from 'Utils/logger';
import { useStorage, useRouter } from './hooks';

const AuthContext = createContext();

export const AuthProvider = (props) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  // remove rememberMe from cookies since it is not used (at least for now)
  // const [rememberMe, setRememberMe] = useLocalStorage('rememberMe');
  const [rememberMe, setRememberMe] = useState();

  const [token, setToken] = useStorage('cookieStorage', 'authToken');
  const [decodedToken, setDecodedToken] = useState(null);
  const [loginFailure, setLoginFailure] = useState(null);
  const [mfaChallengesResponse, setMfaChallengesResponse] = useState();
  const [mfaChallengeAccepted, setMfaChallengeAccepted] = useState();
  const [previewManagerToken, setPreviewManagerToken] = useStorage('localStorage', 'pmToken');
  const [watchManagerToken, setWatchManagerToken] = useStorage('localStorage', 'wmToken');

  const { showSuccess, showError } = useNotifications();

  const isLoading = useCallback(() => setLoading(true), []);
  const isNotLoading = useCallback(() => setLoading(false), []);

  const onLoginSuccess = useCallback(({ data: { token: jwtToken } }, username) => {
    setToken(jwtToken, {
      vEnd: rememberMe ? 30 * 24 * 3600 : undefined,
    });
    isNotLoading();

    try {
      fetch(`${env.REACT_APP_PREVIEW_MANAGER_URL}/api/token/${env.REACT_APP_PREVIEW_MANAGER_KEY}?user=${encodeURIComponent(username)}`)
        .then((response) => response.json())
        .then((data) => setPreviewManagerToken(data.token));
    } catch (error) {
      showError(`${error.message}. Please try again.`);
    }
    if (PERMISSION_WATCH) {
      try {
        fetch(`${env.REACT_APP_PREVIEW_MANAGER_URL}/api/token/${env.REACT_APP_WATCH_MANAGER_KEY}?user=${encodeURIComponent(username)}`)
          .then((response) => response.json())
          .then((data) => setWatchManagerToken(data.token));
      } catch (error) {
        showError(`${error.message}. Please try again.`);
      }
    }
  }, [setToken, rememberMe, isNotLoading, setPreviewManagerToken, setWatchManagerToken, showError]);

  /**
   * Authenticate user & store jwt data
   */
  const login = useCallback((username, password, mfaChallenge = null, mfaValue = null) => {
    isLoading();
    return authenticate(username, password, mfaChallenge, mfaValue)
      .then((data) => onLoginSuccess(data, username))
      .then(() => {
        setLoginFailure(null);
        setMfaChallengesResponse(null);
        setMfaChallengeAccepted(null);
        showSuccess('Login successful!');
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setLoginFailure(null);
          setMfaChallengesResponse(e.response.headers['www-authenticate']);
        } else {
          console.log(e);
          if (e.response.data.message.includes('lockout')) {
            setLoginFailure('lockout');
          } else if (mfaChallenge) {
            setLoginFailure('passcode');
          } else {
            setLoginFailure('credentials');
          }
        }
      })
      .catch(({ response }) => Promise.reject({
        status: response.status,
        ...response.data,
      }));
  }, [isLoading, onLoginSuccess, showSuccess]);

  const acceptMfaChallengeHelper = useCallback((
    username,
    password,
    mfaChallenge,
    apiCall
  ) => (
    apiCall(username, password, mfaChallenge)
      .then((e) => {
        if ('ok' in e.data && e.data.ok) {
          setMfaChallengeAccepted(true);
          setLoginFailure(null);
        } else {
          setLoginFailure('challenge');
        }
      })
      .catch((e) => {
        console.log(e);
        showError('Login failed. Please try again.');
      })
      .catch(({ response }) => Promise.reject({
        status: response.status,
        ...response.data,
      }))
  ), [showError]);

  const acceptMfaChallenge = (username, password, mfaChallenge) => {
    acceptMfaChallengeHelper(username, password, mfaChallenge, challengeAccepted);
  };

  const acceptMfaChallengeNoLogin = (username, uniqueToken, mfaChallenge) => {
    acceptMfaChallengeHelper(username, uniqueToken, mfaChallenge, challengeAcceptedNoLogin);
  };

  const logout = useCallback(() => {
    setToken(undefined);
    setDecodedToken(undefined);
    // clear query cache. Might not be the best place to do it, but must happen on logout
    queryCache.clear();
    router.push(APP_LOGIN_ROUTE);
  }, [setToken, router]);

  const refreshToken = useCallback((username) => {
    isLoading();
    newToken().then((data) => onLoginSuccess(data, username));
  }, [onLoginSuccess, isLoading]);

  /**
   * Refresh the jwt halfway through the expiry
   */
  useEffect(() => {
    if (!token || loading) {
      return undefined;
    }

    // decode jwt data
    const data = (() => {
      try {
        return jwtDecode(token);
      } catch (e) {
        return null;
      }
    })();

    // if jwt decode fails, clear the jwt token
    if (!data) {
      logout();
      return undefined;
    }

    const iat = parseInt(data.iat, 10) * 1000;
    const exp = parseInt(data.exp, 10) * 1000;
    const now = new Date(readServerTime()).getTime();

    // if token is expired, clear the jwt token
    if (exp < now) {
      logout();
      return undefined;
    }

    setDecodedToken(data);
    const duration = exp - iat;
    const renewAt = iat + (duration / 2);
    const renewAfter = renewAt - now;
    debug.log('%cRefreshTokenTimer', 'color: green; font-weight: bold', new Date(now + renewAfter));
    // When staying on the same scope (page),
    // do a token refresh after half the duration of the token
    const c = setTimeout(refreshToken, renewAfter, data.user_id);

    // When navigating to a new scope,
    // force refresh the token if it is older than 2.5minutes
    if ((now - iat) > 0.05 * 60 * 1000) {
      refreshToken(data.user_id);
    }

    return () => clearTimeout(c);
  }, [token, logout, loading, refreshToken, setToken]);

  return (
    <AuthContext.Provider
      value={{
        data: decodedToken,
        token,
        loading,
        isAuthenticated: Boolean(token && decodedToken),
        acceptMfaChallenge,
        acceptMfaChallengeNoLogin,
        login,
        logout,
        rememberMe,
        setRememberMe,
        loginFailure,
        mfaChallengesResponse,
        mfaChallengeAccepted,
        setMfaChallengeAccepted,
        previewManagerToken,
        watchManagerToken,
      }}
      {...props}
    />
  );
};

export const useAuth = () => useContext(AuthContext);

import { lazy } from 'react';
import { PERMISSION_BOOKINGS } from 'Constants';

export default {
  ui: 'menuLeft',
  auth: { role: PERMISSION_BOOKINGS },
  path: 'functions',
  title: 'Functions',
  urlParams: '/:page?',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/transformation-profiles',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/transformation-profiles-view')),
      title: 'Transformation Profiles',
      pageTitle: 'Transformation Profiles',
    },
  ],
};

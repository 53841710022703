import { css } from 'styled-components';

const uiReset = ({ colors }) => css`
  .ui {
    &.form {
      .field {
        width: 100%;

        > label {
          color: ${colors.text};
        }
        &.error input {
          background: ${colors.inputBg} !important;
        }
      }
    }

    &.checkbox {
      label, + label {
        &, &:hover {
          color: ${colors.text};
        }
      }
      input:active ~ label,
      input:focus ~ label {
        color: ${colors.text};
      }
    }
  }

  .ui.form input:not([type]),
  .ui.form input[type=date],
  .ui.form input[type=datetime-local],
  .ui.form input[type=email],
  .ui.form input[type=file],
  .ui.form input[type=number],
  .ui.form input[type=password],
  .ui.form input[type=search],
  .ui.form input[type=tel],
  .ui.form input[type=text],
  .ui.form input[type=time],
  .ui.form input[type=url],
  .ui.form textarea {
    font-family: inherit;
    &, &:focus {
      background: ${colors.inputBg};
      color: ${colors.inputTextColor};
    }
  }

  .ui.form .disabled.field,
  .ui.form .field.disabled>label {
    opacity: 1;
  }

  .ui.disabled.search,
  .ui.disabled.dropdown {
    opacity: .65;
  }

  .ui.disabled.checkbox > label{
    color: #d5d5d5 !important;
  }

  .ui.disabled.search > .text,
  .ui.disabled.dropdown > .text {
    color: #666 !important;
  }

  .ui.selection.dropdown {
    min-width: 0;
  }

  .ui.selection.dropdown,
  .ui.selection.dropdown .menu {
    background: ${colors.inputBg};
    color: ${colors.inputTextColor};

    > .item {
      color: ${colors.inputTextColor};
    }
    > .selected.item {
      background-color: ${colors.inputSelectedBg};
    }
  }

  .ui.selection.visible.dropdown>.text:not(.default) {
    color: ${colors.inputTextColor};
  }

  .ui.default.dropdown:not(.button)>.text,
  .ui.dropdown:not(.button)>.default.text {
    color: rgb(136 136 136);
  }

  .ui.dimmer {
    background: ${colors.modalOverlayBg};
  }

  .ui.modal {
    background: ${colors.modalHeadBg};
    > .header,
    > .actions {
      background: ${colors.modalHeadBg};
      color: ${colors.text};
    }

    > .content {
      background: ${colors.modalContentBg};
      color: ${colors.text};
    }
    &.fullscreen > .close {
      color: ${colors.modalCloseColor};
    }
  }

  .ui.table {
    &.fixed {
      td {
        overflow: auto;
        padding: 0.27em;
        height: auto;
        min-height: 35px;
      }
    }
    thead {
      text-align: center;
    }
    &:not(.clear-border) tr:nth-child(n+2) > td {
      border-top: 4px solid ${colors.bg} !important;
    }
  }

  .ui.selectable.table, .ui.selectable.inverted.table {
    thead {
      th {
        cursor: auto;
      }
      th.sortable {
        cursor: pointer;
      }
      th:hover {
        background-color: ${colors.table.headerBg} !important;
      }
    }
    tbody {
      > tr:hover {
        background-color: transparent !important;
      }
      > tr > td tr:hover {
        background-color: ${colors.table.backgroundHover} !important;
        cursor: pointer;
      }
    }
  }

  .ui-tabs {
    &.inverted {
      .ui.tabular.menu {
        .active.item {
          background: ${colors.uiTabs.background};
          color: ${colors.uiTabs.text}!important;
        }
      }

      .ui.tab {
        background: ${colors.uiTabs.background};
        color: ${colors.uiTabs.text}!important;

        .ui.list {
          > .item {
            line-height: 1.4;
            border-color: rgba(255,255,255,.1);

            > .header {
              color: rgba(255,255,255,.9);
            }
            > .description {
              color: rgba(255,255,255,.7);
            }
          }
        }
      }
    }
  }

  .ui.tabular.menu {
    a.item {
      color: ${colors.text};
      &:hover {
        color: ${colors.linkActive}
      }
    }

    a.item.active {
      color: ${colors.inputTextColor}
    }
  }

  .ui.fullscreen.modal {
    &, &.scrolling {
      left: auto!important;
    }
  }

  .ui.styled.accordion,
  .ui.styled.accordion .accordion {
    &.inverted {
      background: #1b1c1d;
      box-shadow: 0 1px 2px 0 rgba(200,200,200,.035), 0 0 0 1px rgba(200,200,200,.035);

      .title {
        border-top: 1px solid rgba(200,200,200,.15);
        &:first-child {
          border-top: none;
        }
      }
    }
  }

  .ui.toggle.checkbox input:checked~.box, .ui.toggle.checkbox input:checked~label {
    color: white !important;
  }

  .ui.toggle.checkbox label {
    color: rgb(213, 213, 213) !important;
  }

  .ui.toggle.checkbox label:before {
    background: #121314;
  }

  .react-select__group-heading.react-select__group-heading {
    color: #d1d1d1;
    font-size: 100%;
    font-weight: bold;
  }

  .react-select__option--is-focused:not(.react-select__option--is-selected) {
    background-color: #1d1f20;
  }

  .ui.list {
    &.compact {
      > .item {
        display: flex;
        .header {
          flex: 1;
        }
        .description {
          flex: 3;
        }
      }
    }
  }

  .ui.accordion {
    width: 100%;
    margin-bottom: 10px;
    background-color: #34383d;

    > .fields.content.active {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin: 0;
      padding: 25px !important;
      border-top: 1px solid #26292c;
    }

    > .title {
      font-family: inherit !important;
      font-size: 13px !important;
      padding: 0 25px !important;
      display: flex;
      align-items: center;
      height: 60px;
      position: relative;

      > .icon {
        position: absolute;
        right: 25px;
      }
      > .close-expandable {
        display: none;
      }
      > .open-expandable {
        display: inline-flex;
      }
    }

    > .title.active  {
      > .close-expandable {
        display: inline-flex;
      }
      > .open-expandable {
        display: none;
      }
    }
  }

  .field:hover + .ui.pointing.label {
    visibility: visible;
  }
  .formfield:hover + .ui.pointing.label {
    visibility: visible;
  }
  .ui.pointing.label {
    z-index: 1;
    visibility: hidden;
    min-width: auto !important;
    white-space: pre;
  }

  .ui.inverted.segment, .ui.primary.inverted.segment {
    color: rgb(213, 213, 213);
  }

  ${Object.entries(colors.priorityColors).map(([color, value]) => (`
    .ui.${color}.active.button,
    .ui.${color}.button .active.button:active,
    .ui.${color}.buttons .active.button,
    .ui.${color}.buttons .active.button:active {
      background-color: ${value};
    }
  `))}
`;

export default css`
  ${({ theme }) => uiReset(theme)}
`;
